.devices-container {
    background-color: rgb(231, 231, 231);
    /* overflow: auto; */
    /* height: 50%; */

}

 .devices-table {
     width: 100%;
     height: 100%;
}

.devices-table > thead > tr th {
    height: 2rem;
    background-color: rgb(0, 17, 90);
    background-color: rgb(72, 151, 42);


    font-size: 12px;
    position:sticky;
    top:0px;
    color:white;
}
    
.devices-table > tbody tr td {
font-size: 12px; 
/* font-weight: normal; */
/* text-align: center; */
}


.devices-table tr:nth-child(even){background-color: white;}

.devices-table tr:hover {background-color: rgb(177, 174, 174);}



