.config{
    /* justify-content: right;
    align-items: right; */
    margin: 10px;
}

.paper {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.submit-div {
    /* margin-top: 10px; */
    /* width: 150px; */
    margin: 10px 0px 5px auto;
}

.btn-pick-date {
    min-width: 60px;
    
}

.select-date {
    width: auto;
}
.select-date-mode  {
    /* width: 100%; */
    margin-bottom: 10px;

}
