.WeekBarSPL {
    margin: 0; 
    max-width: 100%;
    width: 100%;
    padding: 0;
    /* width: 100%; */
    /* height: 100%;   */
    /* max-height: 300px;  */
    background-color: aqua;
    height: 500px;
  }