.DeviceMapPinHoverText {
  border-radius: 10px;
}

.MapControl {
  padding: 8px;
  border-radius: 4px;
  border:2px solid rgba(0,0,0,0.2);
  background-color: white;
  color: black;
  background-clip: padding-box;
}

.GoogleMap {
  height: auto
}