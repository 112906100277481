.anim-user-loading {
    /* height: 100%; */
    max-width: 300px;
    /* margin: 10% auto 10% auto; */
    margin: auto;
  }


  .anim-base-loading {
    width: 80%;
    height: 100%;
    margin: 20% auto 20% auto;
  }

  .anim-line-loading {
    width: 100%;
    height: 100%;
    margin: auto;
  }